<script>
import SettingsModalBase from "@/components/SettingsModalBase";
import keycloakService from "@/services/KeycloakService";

export default{
    props: [
        'show'
    ],
    emits: [
        'close',
    ],
    components: {
        SettingsModalBase,
    },
    methods: {
        logout() {
            localStorage.clear();
            keycloakService().doLogout();
        }
    },
}
</script>

<template>
    <SettingsModalBase :show="show" :max-width="'sm'" :closeable="true" @close="$emit('close')">
        <div class="flex w-full p-4 flex-col gap-2">
<!--            <button type="button" class="flex w-full p-4 rounded-xl bg-gray-200 justify-between shadow-md hover:bg-gray-300 transition-all">-->
<!--                1-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">-->
<!--                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />-->
<!--                </svg>-->
<!--            </button>-->
<!--            <button type="button" class="flex w-full p-4 rounded-xl bg-gray-200 justify-between shadow-md hover:bg-gray-300 transition-all">-->
<!--                2-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">-->
<!--                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />-->
<!--                </svg>-->
<!--            </button>-->
            <button @click="logout()" type="button" class="flex w-full p-4 rounded-xl bg-red-600 justify-between text-white shadow-md hover:bg-red-700 transition-all">
                Выход
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                </svg>
            </button>
        </div>
    </SettingsModalBase>
</template>