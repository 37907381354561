import axiosInstance from "./request";
import keycloakService from "@/services/KeycloakService";
import {notify} from "@kyvg/vue3-notification";

const setup = () => {
	axiosInstance.interceptors.request.use((config) => {
			const token = keycloakService().getToken();
			if (token) {
				config.headers["Authorization"] = 'Bearer ' + token;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axiosInstance.interceptors.response.use((res) => {
			return res;
		},
		async (err) => {
			if (err.response.status == 401) {
				keycloakService().doLogout();
			}
			notify({
				group: 'errors',
				title: err.name,
				text: err.message,
			});
		})
}

export default setup;