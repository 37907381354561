import Keycloak from 'keycloak-js';

const keycloakSettings = {
	url: process.env.VUE_APP_BASE_KEYCLOAK_URL,
	realm: 'microservices-realm',
	clientId: 'merchant-panel-app',
};

const _kc = new Keycloak(keycloakSettings);

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const updateToken = (successCallback) => _kc.updateToken().then(successCallback).catch(doLogin);

// const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const hasRole = (roles) => roles.some((role) => _kc.hasResourceRole(role));

const ownerName = () => {
	return _kc.tokenParsed.preferred_username;
};

function initKeycloak(authCallback) {
	let roles = ['ROLE_MERCHANT'];

	_kc
		.init({
			onLoad: 'login-required',
		})
		.then((auth) => {
			if (auth) {
				localStorage.setItem('token', getToken());
				if (hasRole(roles)) {
					authCallback();
				} else {
					doLogout();
				}
			} else {
				console.log('Not Authenticated');
				doLogin();
			}
		});
}

const KeycloakService = () => {
	return {initKeycloak, doLogin, doLogout, getToken, updateToken, hasRole, ownerName};
};

export default KeycloakService;