import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import setupInterceptors from "@/services/setupInterceptors";
import KeycloakService from "@/services/KeycloakService";

setupInterceptors();

let keycloak = KeycloakService();

const renderApp = () => {
	createApp(App)
		.use(router)
		.use(KeycloakPlugin)
		.mount('#app')
};

keycloak.initKeycloak(renderApp);
const KeycloakPlugin = {
	install: (app) => {
		app.config.globalProperties.$keycloak = keycloak;
	},
};