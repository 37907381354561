<script>
import AppSidebar from "@/components/AppSidebar";
import SettingsModal from "@/components/SettingsModal";
import request from "@/services/request";

export default {
    name: 'App',
    components: {
        AppSidebar,
        SettingsModal,
    },
    data() {
        return {
            showSettings: false,
            totalTransactions: 0,
            successTransactions: 0,
            failTransactions: 0,
            transactions: {
                total: 0,
                successful: 0,
                error: 0,
            },
        }
    },
    methods: {
        toggleSettings() {
            this.showSettings = !this.showSettings;
        },
        getTransactionsStat() {
            request.get(`/merchant-page/transactions/count`)
                .then((res) => {
                    this.transactions = res.data.message;
                })
        },
    },
    mounted() {
        this.getTransactionsStat();
    },
}
</script>

<template>
    <div class="flex w-full min-h-[100vh] bg-gray-300">
        <AppSidebar/>
        <div class="flex flex-col w-full items-center h-full">
            <div class="flex w-full bg-[#1c2434] h-[400px] justify-center">
                <div class="flex w-full max-w-[95%] flex-col gap-[15px] items-center">
                    <div class="flex w-full mt-6 justify-end">
                        <button @click="toggleSettings()" type="button" class="p-1 rounded-full hover:bg-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                        </button>
                    </div>
                    <div class="flex w-full gap-4">
                        <div class="flex w-full bg-white rounded-xl shadow-xl">
                            <div class="flex flex-col w-full p-4">
                                <div class="flex w-full justify-between">
                                    <div class="flex flex-col gap-1 items-start"><h5
                                        class="text-blueGray-400 uppercase font-bold text-xs">Общее кол-во транзакций</h5>
                                        <span class="font-semibold text-xl text-blueGray-700">
                                            {{ transactions.total.toLocaleString() }}
                                        </span>
                                    </div>
                                    <div class="relative w-auto pl-4 flex-initial">
                                        <div
                                            class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-yellow-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"/>
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"/>
                                            </svg>

                                        </div>
                                    </div>
                                </div>
<!--                                <div class="flex w-full justify-start">-->
<!--                                    <p class="text-sm text-blueGray-400 mt-4">-->
<!--                                        <span class="mr-2 text-emerald-500">-->
<!--                                            3.48%-->
<!--                                        </span>-->
<!--                                        <span class="whitespace-nowrap">-->
<!--                                            Since last month-->
<!--                                        </span>-->
<!--                                    </p>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="flex w-full bg-white rounded-xl shadow-xl">
                            <div class="flex flex-col w-full p-4">
                                <div class="flex w-full justify-between">
                                    <div class="flex flex-col gap-1 items-start"><h5
                                        class="text-blueGray-400 uppercase font-bold text-xs">Успешных транзакций</h5>
                                        <span class="font-semibold text-xl text-blueGray-700">
                                            {{ transactions.successful.toLocaleString() }}
                                        </span>
                                    </div>
                                    <div class="relative w-auto pl-4 flex-initial">
                                        <div
                                            class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-green-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
<!--                                <div class="flex w-full justify-start">-->
<!--                                    <p class="text-sm text-blueGray-400 mt-4">-->
<!--                                        <span class="mr-2 text-emerald-500">-->
<!--                                            3.48%-->
<!--                                        </span>-->
<!--                                        <span class="whitespace-nowrap">-->
<!--                                            Since last month-->
<!--                                        </span>-->
<!--                                    </p>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="flex w-full bg-white rounded-xl shadow-xl">
                            <div class="flex flex-col w-full p-4">
                                <div class="flex w-full justify-between">
                                    <div class="flex flex-col gap-1 items-start"><h5
                                        class="text-blueGray-400 uppercase font-bold text-xs">Неуспешных транзакций</h5>
                                        <span class="font-semibold text-xl text-blueGray-700">
                                            {{ transactions.error.toLocaleString() }}
                                        </span>
                                    </div>
                                    <div class="relative w-auto pl-4 flex-initial">
                                        <div
                                            class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
<!--                                <div class="flex w-full justify-start">-->
<!--                                    <p class="text-sm text-blueGray-400 mt-4">-->
<!--                                        <span class="mr-2 text-emerald-500">-->
<!--                                            3.48%-->
<!--                                        </span>-->
<!--                                        <span class="whitespace-nowrap">-->
<!--                                            Since last month-->
<!--                                        </span>-->
<!--                                    </p>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="w-full max-w-[95%] mt-[-230px] bg-white p-6 rounded-xl shadow-xl">
                <RouterView/>
            </div>
        </div>
    </div>
    <SettingsModal :show="showSettings" @close="showSettings = false"/>
</template>


<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
