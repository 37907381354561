<script>

</script>

<template>
    <div class="flex flex-col gap-4 w-full max-w-[300px] min-h-[100vh] pt-6 bg-gray-200 shadow-xl">
        <div class="flex w-full items-center justify-center flex-col gap-6">
            <p>Merchant Cabinet</p>
            <hr class="w-11/12 h-[2px] bg-black">
        </div>

        <div class="flex flex-col gap-2 px-2">
<!--            <router-link to="/" class="px-4 py-4 rounded-xl flex justify-start gap-4 hover:bg-gray-600 hover:text-white transition-all hover:shadow-md">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">-->
<!--                    <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />-->
<!--                </svg>-->
<!--                Главная-->
<!--            </router-link>-->
            <router-link to="/" class="px-4 py-4 rounded-xl flex justify-start gap-4 bg-white hover:bg-gray-600 hover:text-white transition-all">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                </svg>
                Транзакции
            </router-link>
            <router-link to="/payout" class="px-4 py-4 rounded-xl flex justify-start gap-4 bg-white hover:bg-gray-600 hover:text-white transition-all">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                </svg>
                Payout
            </router-link>
        </div>
    </div>
</template>

<style>
.router-link-active{
    background: #1c2434 !important;
    color: white !important;
}
</style>